@font-face {
  font-family: 'Apercu Pro';
  src: url('./fonts/apercu-pro.eot'); /* IE9*/
  src:
    url('./fonts/apercu-pro.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/apercu-pro.woff') format('woff'),
    /* chrome、firefox */ url('./fonts/apercu-pro.ttf') format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/ url('./fonts/apercu-pro.svg#Apercu Pro') format('svg'); /* iOS 4.1- */
  font-weight: normal;
  font-style: normal;
}
.App {
  font-family: Apercu Pro;
  text-align: center;
}
